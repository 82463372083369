import { UIThemeColors } from '@wearemojo/ui-constants';
import { ComponentType } from 'react';

import PopupInfo from './PopupInfo';
import { BlockContent, MojoIcon } from './utils/types';
import AbstractModalView from './views/AbstractModalView';

type Props = {
	icon?: ComponentType<MojoIcon>;
	iconColor?: string;
	iconContainerSize?: 'lg' | 'xl';
	iconBackgroundColor?: 'background_level_2' | 'background_level_1';
	backgroundColor?: 'background_secondary' | 'background_primary';
	ctaIcon?: ComponentType<MojoIcon>;
	secondaryCtaIcon?: ComponentType<MojoIcon>;
	contentColor?: keyof UIThemeColors;
	fragment: {
		title: string;
		content: BlockContent;
		ctaText: string;
		secondaryCtaText?: string;
		textActionCta?: string;
	};
	onCtaPress: () => void;
	onSecondaryCtaPress?: () => void;
	onTextActionCtaPress?: () => void;
	onClosePress?: () => void;
};

const AbstractModal = ({
	icon,
	iconColor,
	iconContainerSize,
	iconBackgroundColor,
	backgroundColor,
	ctaIcon,
	secondaryCtaIcon,
	contentColor,
	fragment,
	onCtaPress,
	onSecondaryCtaPress,
	onTextActionCtaPress,
	onClosePress,
}: Props) => {
	return (
		<PopupInfo onClosePress={onClosePress} backgroundColor={backgroundColor}>
			<AbstractModalView
				{...fragment}
				icon={icon}
				iconColor={iconColor}
				iconContainerSize={iconContainerSize}
				iconBackgroundColor={iconBackgroundColor}
				ctaIcon={ctaIcon}
				secondaryCtaIcon={secondaryCtaIcon}
				contentColor={contentColor}
				onCtaPress={onCtaPress}
				onSecondaryCtaPress={onSecondaryCtaPress}
				onTextActionCtaPress={onTextActionCtaPress}
			/>
		</PopupInfo>
	);
};

export default AbstractModal;
